<template>
  <div class="page-bg">
    <div class="page-container">
      <router-link to="/">
        <img src="/img/logo_bike.svg" height="61" />
      </router-link>
      <div class="card mt-3">
        <div class="card-body">
          <form class="text-center">
            <h2 >Esqueceu sua senha?</h2>
            <p>Informe seu e-mail de cadastro para receber as instruções de recuperação de senha.</p>

            <label for="inputEmail" class="sr-only">E-mail</label>
            <input
              type="email"
              id="inputEmail"
              class="form-control"
              placeholder="E-mail"
              v-model="email"
              autofocus
            />

            <button
              @click.prevent="onClickEnviar"
              :disabled="loading"
              class="btn btn-primary btn-block mt-2"
            >
              <span v-if="!loading">Enviar</span>
              <span v-if="loading">
                <small class="text-purple">Enviando...</small>
              </span>
            </button>

            <router-link to="/" class="mt-5 d-block">&lt; Voltar para a tela inicial</router-link>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";

import captureError from "../helpers/captureError";
import config from "../config";

export default {
  data() {
    return {
      loading: false,
      email: "",
    };
  },
  methods: {
    async onClickEnviar() {
      if (!this.email) {
        return;
      }

      this.loading = true;

      try {
        await axios.post(
          `${config.API_URL}/auth/esqueciSenha?email=${this.email}`,
          {}
        );

        await Swal.fire({
          icon: "success",
          title: "E-mail de instruções enviado com sucesso.",
        });

        this.$router.replace("/");
      } catch (e) {
        this.loading = false;

        if (e.response && e.response.data && e.response.data.invalido) {
          Swal.fire({
            icon: "warning",
            title: "Atenção",
            text:
              "O e-mail informado não foi encontrado em nossos cadastros. Verifique os dados e tente novamente.",
          });
          return;
        }

        captureError(e);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            "Não foi possível enviar o e-mail. Tente novamente mais tarde ou contate o suporte.",
        });
      }
    },
  },
};
</script>


<style scoped>

.page-container {
  width: 100%;
  max-width: 420px;
  margin: 0 auto;
  padding: 12px;
  padding-top: 7vh;
}

.page-bg {
  background-color: #1c1c1b;
  background-image: url(/img/fundo_home.jpg);
  background-size: cover;
  min-height: 100vh;
}
</style>
